 <template>
    <div class="storeGoodsAdd">
        <div class="storeGoodsAdd-title">
            <span>填写商品信息</span>
        </div>
        <div class="storeGoodsAdd-middle">
            <div class="storeGoodsAdd-middle-span">
                <span></span>
                <span>商品基本信息</span>
            </div>
            <div class="storeGoodsAdd-form">
                <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                    <el-form-item label="商品标题" prop="goods_name" style="width: 100%;margin-top: 20px">
                        <el-input style="width:100%;" placeholder="请输入商品标题" maxlength="50" show-word-limit v-model.trim="form.goods_name"></el-input>
                    </el-form-item>
                    <div class="newLine">
                        <el-form-item label="商品价格" prop="goods_price">
<!--                            <el-input style="width: 300px;" @change="toFixed1" @input="toFixedPrice" v-model="form.goods_price"></el-input>-->
                          <el-input-number style="width: 300px;" class="number-left" v-model="form.goods_price" :controls="false" :precision="2" :min="0.01" :max="9999999999.99"></el-input-number>
                        </el-form-item>
                        <el-form-item label="商品折扣价" prop="goods_zhekou_price" style="margin-left: 8%">
<!--                            <el-input style="width: 300px;" @change="toFixed2" @input="toFixedPrice2" v-model="form.goods_zhekou_price"></el-input>-->
                          <el-input-number style="width: 300px;" class="number-left" v-model="form.goods_zhekou_price" :controls="false" :precision="2" :min="0.01" :max="9999999999.99"></el-input-number>
                        </el-form-item>
                    </div>
                    <div class="newLine">
                        <el-form-item label="行业分类" prop="category_id">
                            <el-select v-model="form.category_id" placeholder="请选择" style="width: 300px;">
                                <el-option
                                        v-for="item in industryOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="店铺分类" prop="store_class_id" style="margin-left: 8%">
                                <el-cascader  @change="shopClassifyChange" v-model="form.store_class_id" :options="shopOptions" :show-all-levels="false" :props="defaultParams"></el-cascader>
                        </el-form-item>
                    </div>
                    <div class="storeGoodsAdd-middle-span">
                        <span></span>
                        <span>图片</span>
                    </div>
                    <div style="position: relative;padding-left: 30px;margin-top: 25px"><b style="font-weight: 500;position: relative">商品图片<i style="color: #FF0000;font-size: 14px;position: absolute;left: -10px">*</i></b></div>
                    <div class="showPicture">
                        <p style="position: relative">商品展示图<b style="position: absolute;left: -10px;color: #FF0000;font-size: 14px">*</b></p>
                        <div class="imgBox">
                            <div class="imgBox-item" v-for="(item,index) in imgSrcList" :key="index">
                                <i class="iconfont" @click="deleImg(index)">&#xe608;</i>
                                <img :src="item" alt="">
                            </div>
                        </div>
                        <div class="showPictureUpload">
                            <div class="uploadBtn" onclick="imgInput.click()">
                                <i class="iconfont">&#xe77f;</i>
                                <span style="margin-top: 4px">{{imgSrcList.length>0?'继续上传':'点击上传'}}</span>
                            </div>
                            <input
                                    ref="modelInput"
                                    type="file"
                                    id="imgInput"
                                    @change="uploadImg($event)"
                                    style="display:none"
                                    accept="image/jpeg,image/png,image/jpg"
                            />
                            <span class="hint">图片至少上传3张, 尺寸800px*800px，仅支持jpg、jpeg、png格式，大小不能超过3M！</span>
                        </div>
                    </div>
                    <div class="storeGoodsAdd-middle-span">
                        <span></span>
                        <span style="position: relative">商品视频</span>
                    </div>
                    <div style="color: rgb(102, 102, 102);font-size: 14px;font-weight: 500;padding-left: 20px;padding-top: 26px;position:relative;margin-bottom: 10px;">
                        商品主图视频设计规范：视频尺寸比例为1:1或者16:9，MP4格式，视频时长≤60秒，清晰度≥720p，视频大小≤150MB。<br/>上传后展示在商品轮播图位置首位。
                    </div>
                    <div class="goods-master-video">
                        <div class="goods-video-upload-box" v-if="!masterVideo">
                            <div class="icon-text-content">
                                <p v-show="!uploadStatus"><i class="el-icon-video-play"></i></p>
                                <p v-html="uploadText"></p>
                            </div>
                            <input type="file" id="master-up-btn" ref="master" class="master-up-btn" accept=".mp4" @change="uploadAsyncFile($event)">
                            <div class="upload-process-line" v-show="uploadStatus" :style="{width: uploadProgress + `%`}"></div>
                        </div>
                        <div class="video-preview-box goods-video-upload-box" v-else>
                            <div class="delete-btn" @click="resetVideo">
                                <i class="el-icon-delete"></i>
                            </div>
                            <div class="preview-img">
                                <img :src="previewMaster" alt="">
                            </div>
                            <div class="play-font-btn" @click="previewVideo">
                                <span>播放</span>
                            </div>

                        </div>
                    </div>
                    <div class="storeGoodsAdd-middle-span">
                        <span></span>
                        <span style="position: relative">商品描述</span>
                    </div>
                    <div style="color: #666666;font-size: 14px;font-weight: 500;padding-left: 20px;padding-top: 26px;position:relative;">商品详情<span style="font-size: 12px;margin-left: 10px">(图片单次最多上传20张)</span><i style="color: #FF0000;font-size: 14px;position: absolute;left: 10px">*</i></div>
                    <div id="editor-shopDetail" style="margin-top: 20px;margin-left: 12px"></div>
                </el-form>
                <div class="footer">
                    <span @click="submitForm">提交</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WE from "./../../../utils/wEditor";
    import {randomString} from "@/utils/utils";
    import {uploadVideo} from "@/utils/apis";

    export default {
        name: "StoreGoodsAdd",
        data(){
            return {
                form:{
                    goods_name:'',
                    goods_price: void 0,
                    goods_zhekou_price: void 0,
                    category_id:'',
                    store_class_id:''
                },
                industryOptions:[],
                shopOptions:[],
                editorText:null,
                masterVideo: "",
                rules: {
                    goods_name: [
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                    ],
                    goods_price: [
                        { required: true, message: '请输入商品价格', trigger: 'blur' },
                        // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    goods_zhekou_price: [
                        { required: true, message: '请输入商品折扣价格', trigger: 'blur' },
                        // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    category_id: [
                        { required: true, message: '请选择活动区域', trigger: 'change' }
                    ],
                },
                defaultParams: {
                    label: 'store_class_name',
                    value: 'id',
                    children: 'children'
                },
                imgList:[],
                imgSrcList:[],
                bannerInfo:[],
                chunkSize: 1024 * 1024, //1MB
                uploadProgress: 0,
                uploadText: "上传视频",
                uploadStatus: false,
                previewMaster: "",
            }
        },
        created() {
            this.getStoreClassList();
            this.getCategoryList();
            this.editShopData();
        },
        beforeDestroy() {
            this.uploadStatus = false
        },
        mounted() {
            this.editorText = new WE('#editor-shopDetail',this.$api.uploadGoodsConImg, 'file[]');
        },
        methods: {
            async uploadAsyncFile(event) {
                const file = event.target.files[0]
                if (this.imgSrcList.length < 1) {
                    this.$refs.master.value = ""
                    this.$message.warning("请先上传商品主图")
                    return
                }
                // if (file.size > 1024 * 1024 * 150) {
                //     this.$refs.master.value = null
                //     this.$message.warning("请先上传商品视频大小 ≤ 150MB")
                //     return
                // }
                let totalChunks = 100
                let chunkSize = this.chunkSize
                if (file.size > 1024 * 1024 * 150) {
                    totalChunks = Math.ceil(file.size / chunkSize)
                } else {
                    chunkSize = file.size / 100
                }
                this.uploadStatus = true
                this.uploadText = `上传中：${this.uploadProgress}%`

                const randomStr = randomString(10)
                let chunkIndex = 0
                while (chunkIndex < totalChunks) {
                    //中途暂停则停止上传
                    if (!this.uploadStatus) {
                        return
                    }
                    let form = new FormData()
                    const start = chunkIndex * chunkSize
                    const end = Math.min(start + chunkSize, file.size)
                    const chunk = file.slice(start, end)
                    form.append("video", chunk)
                    form.append("index", chunkIndex)
                    form.append("total", totalChunks)
                    form.append("random", randomStr)
                    form.append("filename", file.name)
                    try {
                        const res = await uploadVideo(form)
                        if (res.code === 200) {
                            chunkIndex++
                            this.uploadProgress = ((chunkIndex / totalChunks) * 100).toFixed(2)
                            this.uploadText = `上传中：${this.uploadProgress}%`
                            if (res.data && res.data.url) {
                                this.uploadProgress = 100
                                this.masterVideo = res.data.url
                                this.previewMaster = this.imgSrcList[0]
                                this.uploadStatus = false
                            }
                        } else {
                            this.resetVideo()
                            this.$message.error(res.msg)
                        }
                    } catch (e) {
                        this.resetVideo()
                        this.$message.error("服务器异常")
                        console.log("error", e)
                    }



                }
            },
            resetVideo() {
                this.masterVideo = ""
                this.uploadStatus = false
                this.uploadProgress = 0
                this.previewMaster = ""
                this.uploadText = "上传视频"
                if (this.$refs.master) {
                    this.$refs.master.value = ""
                }

            },
            previewVideo() {
                console.log("播放")
                if (this.masterVideo) {
                    window.open(this.masterVideo, "_blank")
                }
            },
            editShopData(){
                if(this.$route.query.editID){
                    this.$http.axiosGetBy(this.$api.editGoods, {id:this.$route.query.editID}, res=>{
                        if(res.code === 200){
                            let resData = res.data;
                            this.form.goods_name = resData.goods_name;
                            this.form.goods_price = resData.goods_price;
                            this.form.goods_zhekou_price = resData.goods_zhekou_price;
                            this.form.category_id = resData.category_id
                            let kongList = [];
                            for (let i = 0; i < this.shopOptions.length; i++) {
                                if (this.shopOptions[i].id == resData.store_class_id) {
                                    kongList.push(this.shopOptions[i].id)
                                } else {
                                    if (this.shopOptions[i].children) {
                                        for (let j = 0; j < this.shopOptions[i].children.length; j++) {
                                            if (this.shopOptions[i].children[j].id == resData.store_class_id) {
                                                kongList.push(this.shopOptions[i].id);
                                                kongList.push(this.shopOptions[i].children[j].id);
                                            }
                                        }
                                    }
                                }
                            }
                            this.form.store_class_id = kongList;
                            resData.goods_image.map(item=>{
                                this.imgSrcList.push(item.image_url);
                                this.imgList.push(item.image)
                            })
                            this.editorText.setWEHtml(resData.goods_content)
                            if (res.data.goods_video) {
                                this.masterVideo = res.data.goods_video
                                this.previewMaster = this.imgSrcList[0]
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            // 店铺分类
            getStoreClassList(){
                this.$http.axiosGet(this.$api.storeClassList, res=>{
                    if(res.code === 200){
                        this.shopOptions = this.getTreeData(res.data.list)
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toFixedPrice(){
                if(!Number(this.form.goods_price) || Number(this.form.goods_price)<=0){
                    this.form.goods_price = ''
                }
            },
            toFixed1(){
                if(Number(this.form.goods_price)){
                    this.form.goods_price = Number(this.form.goods_price).toFixed(2)
                }
            },
            toFixedPrice2(){
                if(!Number(this.form.goods_zhekou_price) || Number(this.form.goods_zhekou_price)<=0){
                    this.form.goods_zhekou_price = ''
                }
            },
            toFixed2(){
                if(Number(this.form.goods_zhekou_price)){
                    this.form.goods_zhekou_price = Number(this.form.goods_zhekou_price).toFixed(2)
                }
            },
            getTreeData(data){
                for(var i=0;i<data.length;i++){
                    if(data[i].children.length<1){
                        data[i].children=undefined;
                    }else {
                        this.getTreeData(data[i].children);
                    }
                }
                return data;
            },
            // 行业分类
            getCategoryList(){
                this.$http.axiosGet(this.$api.categoryList, res=>{
                    if(res.code === 200){
                        this.industryOptions = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            shopClassifyChange(val){
                let classValue = val[val.length-1];
                this.form.store_class_id = classValue;
            },
            uploadImg(event){
                if(this.imgList.length === 5 || this.imgList.length + event.target.files.length > 5){
                    this.$message.warning('已超过图片数量限制，最多上传5张！');
                    return
                }
                let files = event.target.files;
                let fileSize = event.target.files[0].size;
                let filesSize = fileSize / 1024 / 1024 < 30;
                let fileType = ['png','jpeg','jpg','mp4'];
                if(!fileType.includes(files[0].name.split('.')[files[0].name.split('.').length - 1].toLowerCase())){
                    this.$message.warning('图片格式错误！');
                    return;
                }
                if(!filesSize){
                    this.$message.warning('图片大小不能超过3M！');
                    return;
                }

          let reader = new FileReader()
          reader.onload = (e) => {
            let data = e.target.result
            let image = new Image()
            image.onload = () => {
              let width = image.width
              let height = image.height
              if (width === 800 && height === 800) {
                let formData = new FormData();
                formData.append('file',files[0]);
                this.$http.axiosPost(this.$api.uploadGoodsImg, formData, res=>{
                    if(res.code === 200){
                        this.imgSrcList.push(res.data.url2);
                        this.imgList.push(res.data.url)
                        this.$refs.modelInput.value = '';
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                    this.$refs.modelInput.value = '';
                })
              } else {
                this.$message.warning('上传尺寸为800px*800px')
                this.$refs.modelInput.value = '';
                return false
              }
            }
            image.src = data
          }
          reader.readAsDataURL(files[0])

            },
            deleImg(index){
                this.imgSrcList.splice(index,1);
                this.imgList.splice(index,1);
            },
            submitForm(){
                // console.log(236,this.editorText.getWEHtml())
                // return;
                if(this.form.goods_name && this.form.goods_price && this.form.goods_zhekou_price && this.form.category_id && this.editorText.getWEHtml() != '<p><br></p>'){
                    if(!Number(this.form.goods_zhekou_price) || !Number(this.form.goods_price)){
                        this.$message('商品价格和折扣价必须是数字！');
                        return;
                    } else if(Number(this.form.goods_zhekou_price) > Number(this.form.goods_price)){
                        this.$message('折扣价不能大于价格！')
                        return;
                    }
                    if(this.imgSrcList.length < 3 ){
                        this.$message.warning('图片最少上传三张！');
                        return
                    }
                    this.form.goods_image = this.imgList;
                    this.form.goods_content = this.editorText.getWEHtml();
                    this.form.goods_video = this.masterVideo
                    if(this.$route.query.editID){
                        this.form.id = this.$route.query.editID
                    }
                    // let formData = new FormData();
                    // formData.append('goods_name',this.form.title);
                    // formData.append('goods_price',this.form.price);
                    // formData.append('goods_content',this.editorText.getWEText());
                    // formData.append('goods_image',this.imgList);
                    // formData.append('goods_zhekou_price',this.form.discountPrice);
                    // formData.append('category_id',this.form.industryClassify);
                    // formData.append('store_class_id',this.form.shopClassify);
                    this.$http.axiosPost(this.$api.shopSaveGoods, this.form, res=>{
                        if(res.code === 200){
                           this.$message.success('发布成功！');
                           this.$router.push({
                               path:'/student/storedecorate/storegoods'
                           })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } else {
                    this.$message.warning('必填项或必选项不能为空！')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-master-video {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        .goods-video-upload-box {
            .upload-process-line {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 5px;
                background: #68C23A;
              border-radius: 2px;
            }
            position: relative;
            width: 100px;
            height: 100%;
            border: 1px dashed #DBDBDB;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-text-content {
                font-size: 14px;
                color: #717171;
                p {
                    text-align: center;
                    i {
                      font-size: 20px;
                    }
                }
            }
            .master-up-btn {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 999;
                opacity: 0;
                cursor: pointer;
            }
        }
        .video-preview-box {
            border: none;
            flex-direction: column;
            .delete-btn {
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #fff;
                opacity: .6;
                i {
                  color: #F56C6C;
                }
            }
            .preview-img {
                width: 100%;
                height: 100%;
                img {
                  width: 100%;
                  height: auto;
                }
            }
            .play-font-btn {
                cursor: pointer;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                background: #1a1a1a;
                opacity: .6;
                span {
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
    /deep/.w-e-toolbar{
        height: 60px;
        line-height: 50px;
    }
    /deep/.w-e-text-container{
        height: 500px!important;
    }
    .newLine{
        display: flex;
    }
    .showPicture{
        margin-top: 20px;
        padding: 20px;
        background: #F5F7F9;
        //margin-left: 100px;
        .imgBox{
            padding-top: 20px;
            display: flex;
            .imgBox-item{
                width: 70px;
                height: 70px;
                /*overflow: hidden;*/
                border: 1px solid #E5E5E5;
                position: relative;
                margin-left: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    color: #d81e06;
                    font-size: 12px;
                    cursor: pointer;
                }
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .imgBox-item:nth-child(1){
                margin-left: 0;
            }
        }
        .showPictureUpload{
            .uploadBtn{
                margin-top: 20px;
                cursor: pointer;
                width: 71px;
                height: 71px;
                border: 1px solid #E5E5E5;
                background: #FFFFFF;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span{
                    color: #999999;
                    font-size: 12px;
                    font-weight: 500;
                }
                i{
                    font-size: 18px;
                    color: #BFBFBF;
                }
            }
            .hint{
                display: block;
                margin-top: 20px;
                color: #999999;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .storeGoodsAdd{
        padding: 20px 26px;
        background: #ffffff;
        .storeGoodsAdd-title{
            color: #333333;
            font-weight: bold;
            font-size: 16px;
        }
        .storeGoodsAdd-middle{
            .storeGoodsAdd-middle-span{
                padding-top: 18px;
                color: #333333;
                font-weight: bold;
                font-size: 14px;
                span:nth-child(1){
                    width: 3px;
                    height: 10px;
                    background: #FF4D77;
                    display: inline-block;
                }
                span:nth-child(2){
                    margin-left: 10px;
                }
                .storeGoodsAdd-form{
                    margin-top: 20px;
                }
            }

        }
    }
    .footer{
        span{
            width: 94px;
            height: 40px;
            background: #FD4446;
            display: block;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            border-radius: 2px;
            margin: 20px auto;
            cursor: pointer;
        }
    }
</style>